import { useEffect, useRef, useState } from 'react';
import { useGameStore } from 'shared/store';
import { getValuesForTimer } from 'shared/utils';

type useGameTimerProps = {
  startValue: number;
};

export const useGameTimer = ({ startValue }: useGameTimerProps) => {
  const [seconds, setSeconds] = useState(startValue);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const incDonSpeedInterval = useGameStore((state) => state.incDonSpeedInterval);
  const incDonutSpeed = useGameStore((state) => state.incDonutSpeed);
  const setGameFinished = useGameStore((state) => state.setGameFinished);

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      setSeconds((prev) => (prev - 1 > 0 ? prev - 1 : 0));
    }, 1000);

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    const handleTimer = async () => {
      if (seconds < 1) {
        setGameFinished(true);
        intervalRef.current && clearInterval(intervalRef.current);
        return;
      }

      if (seconds !== startValue && seconds % incDonSpeedInterval === 0) {
        incDonutSpeed(0.07);
      }
    };
    handleTimer();
  }, [seconds]);

  return { gameTime: getValuesForTimer(seconds), seconds };
};
