import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { PlatformProvider } from 'shared/providers';
import { overrideWindowOpen } from 'shared/utils';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

window?.Telegram?.WebApp.expand();
window?.Telegram?.WebApp.disableVerticalSwipes();
window?.Telegram?.WebApp.ready();
overrideWindowOpen();

root.render(
  <BrowserRouter>
    {/* <PlatformProvider> */}
    <App />
    {/* </PlatformProvider> */}
  </BrowserRouter>,
);
