import styles from './style.module.scss';
import { CoinsCounter } from './coins-counter';
import { GameTimer } from './game-timer';
import { useGameTimer } from 'shared/hooks';
import { useGameStore } from 'shared/store';
import { Swipe } from 'shared/ui/animations';

export const GamePanel = () => {
  const settings = useGameStore((state) => state.settings);
  const gameTime = useGameTimer({ startValue: settings.session_duration });

  return (
    <div className={styles.root}>
      <Swipe direction="vertical" from="top" style={{ width: '100%' }} delay={0.1}>
        <div className={styles.container}>
          <GameTimer timerValue={gameTime} />
          <CoinsCounter />
        </div>
      </Swipe>
    </div>
  );
};
