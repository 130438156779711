import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finishSession, startSession } from 'shared/api';
import { ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { checkEarnedCoins, handleErrorWithAlert, handleErrorStatus } from 'shared/utils';

type StopGameParams = {
  limitReached: boolean;
  _currCoins: number;
  failedCoins: number;
  _catchedBees: number;
  _sessionId: string;
};

export function useGame() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useGlobalStore((state) => state.user);
  const sessionId = useGameStore((state) => state.sessionId);
  const catchedBees = useGameStore((state) => state.catchedBees);
  const failedDonuts = useGameStore((state) => state.failedDonuts);
  const currCoins = useGameStore((state) => state.currCoins);
  const donutsCleared = useGameStore((state) => state.donutsCleared);
  const setSessionId = useGameStore((state) => state.setSessionId);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const increaseCurrentLimit = useGlobalStore((state) => state.increaseCurrentLimit);
  const resetGameState = useGameStore((state) => state.resetGameState);

  const startGame = useCallback(async () => {
    try {
      resetGameState();

      const res = await startSession();
      setSessionId(res.data.uuid);
      updateUser({ last_start: res.data.last_start });
    } catch (error) {
      navigate(ROUTES.MAIN, { replace: true });
      handleErrorWithAlert(error);
    }
  }, []);

  const leaveSession = (limitReached: boolean, coins: number) => {
    if (limitReached) {
      navigate(ROUTES.LIMIT_REACHED, {
        replace: true,
        state: { coins: coins },
      });
    } else {
      navigate(ROUTES.CONGRATULATIONS, {
        replace: true,
        state: { coins: coins },
      });
    }
  };

  const stopGame = useCallback(
    async ({ _currCoins, failedCoins, _catchedBees, limitReached, _sessionId }: StopGameParams) => {
      try {
        if (!_sessionId) return;

        const earnedCoins = checkEarnedCoins({
          dailyLimit: user.daily_limit + user.daily_bonus_limit,
          currCoins: _currCoins,
          currentLimit: user.current_limit,
          limitReached: limitReached,
        });

        const res = await finishSession(_sessionId, earnedCoins, _catchedBees, failedCoins);
        increaseCurrentLimit(earnedCoins);
        updateUser({ coins: res.data.total_coins });
        leaveSession(limitReached, earnedCoins);
      } catch (error) {
        handleErrorStatus(error, 404, () => {
          alert(t('alerts.you_already_started_session'));
          navigate(ROUTES.MAIN, { replace: true });
        });
      } finally {
        resetGameState();
      }
    },
    [],
  );

  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    const handleFinishGame = async () => {
      if (!donutsCleared || !sessionId) return;

      const isLimitReached =
        currCoins + user.current_limit >= user.daily_limit + user.daily_bonus_limit;

      await stopGame({
        _currCoins: currCoins,
        failedCoins: failedDonuts,
        _catchedBees: catchedBees,
        limitReached: isLimitReached,
        _sessionId: sessionId,
      });
    };
    handleFinishGame();
  }, [donutsCleared]);

  return {
    startGame,
    stopGame,
  };
}
